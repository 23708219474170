import {
  BUILDINCLASS,
  DEFAULTMARGINBOTTOM,
  DEFAULTMARGINTOP,
  DEFAULTTHRESHOLD,
  animationOptions,
  cancelAnimationsForElement,
  shouldReduceMotion,
} from './animate-on-scroll'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

const DEFAULTTYPESPEED = 20
const DEFAULTROWSPEED = 200
const DEFAULT_NUMBER_INCREMENT_SPEED = 75
const ROWVISIBLECLASS = 'row-is-visible'

observe('.js-type-in, .js-type-in-item', element => {
  if (shouldReduceMotion(element)) return
  resetText(element)
})

const defaultObserver = new IntersectionObserver(setAnimationState, {
  rootMargin: `-${DEFAULTMARGINTOP}% 0% -${DEFAULTMARGINBOTTOM}% 0%`,
  threshold: DEFAULTTHRESHOLD,
})

observe('.js-type-in, .js-type-in-trigger', element => {
  // Listen to prefers reduced motion, and cancel all animation if true
  if (shouldReduceMotion(element)) {
    cancelAnimationsForElement(element)
    return
  }

  const options = animationOptions(element)
  if (options.isDefault) return defaultObserver.observe(element)

  const customObserver = new IntersectionObserver(setAnimationState, {
    rootMargin: `-${options.marginTop}% 0% -${options.marginBottom}% 0%`,
    threshold: options.threshold,
  })
  customObserver.observe(element)
})

function setAnimationState(entries: IntersectionObserverEntry[]) {
  for (const entry of entries) {
    entry.isIntersecting ? startTextAnimation(entry.target) : resetText(entry.target)
    if (!entry.target.classList.contains('js-type-in-trigger')) continue

    for (const item of entry.target.querySelectorAll('.js-type-in-item, .js-build-number')) {
      entry.isIntersecting ? startTextAnimation(item) : resetText(item)
    }
  }
}

function targetChildNodes(element: Element) {
  // Convert children text nodes to spans
  const childNodes = element.childNodes
  for (const childNode of childNodes) {
    if (childNode.nodeName === '#text') {
      const span = document.createElement('span')
      span.textContent = childNode.textContent
      childNode.replaceWith(span)
    }
  }

  // Move the animation class to the children elements
  const children = element.querySelectorAll<HTMLElement>('*')
  for (const child of children) {
    child.classList.add('js-type-letters')
    child.style.visibility = 'hidden'
  }
  element.classList.remove('js-type-letters')
}

function resetText(element: Element) {
  if (!element.classList.contains('js-type-in') && !element.classList.contains('js-type-in-trigger')) return // Don't trigger if it's not a type-in element
  const items = element.querySelectorAll<HTMLElement>('.js-type-row, .js-type-letters')
  for (const item of items) {
    if (item.classList.contains('js-type-letters') && item.children.length > 0) {
      // If the element has children elements, target them instead
      targetChildNodes(item)
    } else {
      item.style.visibility = 'hidden'
      item.classList.remove(ROWVISIBLECLASS)
    }
  }
  element.classList.remove(BUILDINCLASS)
}

export function startTextAnimation(element: Element) {
  if (element.classList.contains(BUILDINCLASS)) return // Only trigger once
  if (!element.classList.contains('js-type-in') && !element.classList.contains('js-type-in-trigger')) return // Don't trigger if it's not a type-in element
  element.classList.add(BUILDINCLASS)
  const items = element.querySelectorAll('.js-type-row, .js-type-letters')
  const delay = Number(element.getAttribute('data-type-delay') || DEFAULTTYPESPEED)
  const rowDelay = Number(element.getAttribute('data-type-row-delay') || DEFAULTROWSPEED)

  if (element.classList.contains('js-build-number')) {
    setTimeout(() => animateNumber(element, 0, Number(element.textContent)), delay)
  } else {
    setTimeout(() => animateText(element, items, 0, '', rowDelay), delay)
  }
}

function restartTextAnimation(element: Element) {
  resetText(element)
  startTextAnimation(element)
}

function animateText(element: Element, elements: NodeListOf<Element>, index: number, text: string, rowDelay: number) {
  if (index >= elements.length) return

  const item = elements[index] as HTMLElement
  if (!element.classList.contains(BUILDINCLASS)) {
    if (text !== '') item.textContent = text
    return
  }
  // If it has class js-type-row, show it and continue
  if (item.classList.contains('js-type-row')) {
    // Allow row delay override per item
    const itemDelay = Number(item.getAttribute('data-type-row-delay') || rowDelay)

    item.style.visibility = 'visible'
    item.classList.add(ROWVISIBLECLASS)
    index++
    setTimeout(() => animateText(element, elements, index, '', itemDelay), itemDelay)
    return
    // We're here if it's the first time we're starting the animation of a letter animation
  } else if (item.style.visibility === 'hidden' && item.textContent != null) {
    text = item.textContent
    item.textContent = ''
    item.style.visibility = 'visible'
    item.classList.add('animation-is-typing')
  }

  if (item.textContent != null && text.length > item.textContent.length) {
    item.textContent = text.substr(0, item.textContent.length + 1)
  } else {
    index++
    if (index < elements.length) item.classList.remove('animation-is-typing')
  }

  setTimeout(() => animateText(element, elements, index, text, rowDelay), DEFAULTTYPESPEED)
}

function animateNumber(element: Element, currentValue: number, targetValue: number) {
  // Handle float numbers
  if (targetValue % 1 !== 0) {
    currentValue += Math.max(0.1, targetValue / 20)
    currentValue = Number(currentValue.toFixed(1))
  } else {
    currentValue += Math.max(1, Number(targetValue / 35))
    currentValue = Number(currentValue.toFixed(0))
  }

  if (currentValue > targetValue) currentValue = targetValue

  element.textContent = currentValue.toString()
  if (currentValue >= targetValue) return
  const incrementSpeed = Number(element.getAttribute('data-increment-speed') || DEFAULT_NUMBER_INCREMENT_SPEED)
  setTimeout(() => animateNumber(element, currentValue, targetValue), incrementSpeed)
}

on('click', '.js-type-restart', event => {
  const target = event.currentTarget.closest<HTMLElement>('.js-type-in')!
  restartTextAnimation(target)
})
